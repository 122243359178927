body {
    background: #fff;
    font-size: 12pt;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

h1, h2, h3, h4 {
    font-family: 'Playfair Display', serif;
}

p {
    margin: 1.5em 0;
}

a {
    color: red;
}



#header {

    padding: 2rem 0;
    line-height: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: .9rem;
    letter-spacing: .1em;
    text-align: center;

    .leftside {

        span {
            display: block;

            &.name {
                font-size: 1.1em;
                letter-spacing: .2em;
                font-weight: 600;
            }
            
            &.title {
                color: #999;
                font-size: .9em;

                transition: color;
                transition-duration: .5s;    
            }
        }
    }

    .rightside {
        font-size: .8em;

        padding: 2rem 0;
        border-top: 1px solid #e3e3e3;
        border-bottom: 1px solid #e3e3e3;
        margin-top: 2rem;

        span {
            display: block;
        }

        a {
            color: #999;

            transition: color;
            transition-duration: .5s;

        }

    }

    &:hover {

        .leftside span.title {
            color: red;
        }

        .rightside a {
            color: black;

            &:hover {
                color: red;
            }

        }
    }


}





#intro {

    h1 {
        font-size: 2.2rem;
        letter-spacing: -.01em;
        line-height: 1.2em;
    }

    p {
        letter-spacing: -0.03em;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.5em;
        color: #555;
        margin-top: 1em;
    }
}





.row.section_header {

    margin-top: 4rem;

    h2 {
        text-transform: uppercase;
        font-size: .9rem;
        letter-spacing: .1em;
        color: #999;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
    }

}



.portfolio_project {

    padding-top: 4rem;
    border-top: 1px solid #e3e3e3;


    margin-bottom: 3rem;

    h3 {
        font-family: 'Playfair Display', serif;
        font-size: 2.4rem;
    }

    p {
        font-family: 'Montserrat', sans-serif;
    }

    img {
        margin-bottom: 1rem;
    }
}

.strp-window {
    box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.75);
}



#contact_cta {
    margin-top: 3rem;
    margin-bottom: 6rem;
    padding-top: 3rem;
    border-top: 1px solid #e3e3e3;

    h2 {
        font-size: 3rem;
        margin-bottom: 1rem;
    }
}




// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

    body {
        text-align: left;
    }

    #header {
        padding: 6rem 0;
        text-align: left;

        .rightside {
            text-align: right;
            border: none;
            padding: 0;
            margin: 0;
        }
    }

    #intro {

        h1 {
            font-size: 2.8rem;
        }
    
        p {
            letter-spacing: -0.03em;
            font-size: 1.2rem;
            margin-top: 1em;
        }
    }

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

    #header {
        padding: 3rem 0 4rem;
    }

    #intro {

        h1 {
            font-size: 3.5rem;
        }
    
        p {
            letter-spacing: -0.03em;
            font-size: 1.4rem;
            margin-top: 1em;
        }
    }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

    #header {
        padding: 6rem 0;
    }

    #intro {

        h1 {
            font-size: 4rem;
        }
    
        p {
            letter-spacing: -0.03em;
            font-size: 1.6rem;
            margin-top: 1em;
        }
    }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

    #intro {

        h1 {
            font-size: 6rem;
        }
    
        p {
            letter-spacing: -0.03em;
            font-size: 1.8rem;
            margin-top: 1em;
        }
    }

}